const Loading = () => {
    return (
      <div>
         <div className='flex justify-center items-center min-h-[600px]'>
        <span className='loading'></span>
      </div>
      </div>
    )
  }
  
  export default Loading